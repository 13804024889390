<template>
    <v-container fluid>
        <div class="pl-4 pr-0 pt-2 d-flex">
            <div class="pr-0 pt-4 left_filter">
                <setting-menu @filter_show="handleFilterShow"></setting-menu>
            </div>
            <div class="div_body pl-3 pr-0 mx-0">
                <v-form @submit.prevent="getTypeExpenses">
                    <v-btn v-show="$vuetify.breakpoint.xsOnly" :to="{name: 'type_expense.create'}"
                           color="indigo" fab fixed top right class="v-btn--add-form-top white--text">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                        <v-card flat class="background_color_transparent">
                            <v-card-text class="py-0">
                                <v-row class="pt-5">
                                    <v-col cols="12" sm="8" class="title_menu mt-1">
                                        {{  $t('type_expenses') }}
                                    </v-col>
                                    <v-col v-if="!$vuetify.breakpoint.xsOnly" v-show="!filter_show" cols="12" sm="4" class="text-right">
                                        <v-btn :to="{name: 'type_expense.create'}" class="infinity_button" color="primary">
                                            {{$t('create') }}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-row v-if="filter_show">

                                    <v-col class="py-0" cols="12">
                                        <ValidationProvider ref="type_expense" rules="min:2|max:255"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="type_expense" type="text" :error-messages="errors"
                                                          :label="$t('type_expense_name')"
                                                          outlined
                                                          solo
                                                          flat
                                                          dense
                                                          :disabled="loading"
                                                          :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                          color="primary"
                                                          class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                          clearable></v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12">
                                        <ValidationProvider ref="business_process" rules=""
                                                            v-slot="{ errors, valid }">
                                            <v-select v-model="business_process"
                                                      :items="businessProcessItems" :error-messages="errors"
                                                      :error="!valid"
                                                      item-text="name" item-value="id"
                                                      :label="$t('business_process')"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      :disabled="loading"
                                                      :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                      clearable></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions class="px-4 py-4" v-if="filter_show">
                                <v-row>
                                    <v-col cols="12" sm="3" md="2">
                                        <v-select :value="perPage" :items="perPageItems"
                                                  :label="$t('items_per_page')"
                                                  @input="perPage = options.itemsPerPage = Number($event)"
                                                  hide-details
                                                  outlined
                                                  solo
                                                  flat
                                                  dense
                                                  :disabled="loading"
                                                  :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                  color="primary"
                                                  class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                        ></v-select>
                                    </v-col>

                                    <v-col cols="12"  sm="9" md="10" class="d-flex justify-center justify-sm-end align-self-center">
                                        <v-btn type="submit" :disabled="invalid || loading" :block="$vuetify.breakpoint.xsOnly"
                                               color="primary" class="infinity_button">{{ $t('search') }}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-actions>

                        </v-card>
                    </ValidationObserver>
                </v-form>
                <v-card flat class="background_color_transparent">
                    <v-card-text class="pt-7">
                        <v-data-table :headers="headers" :items="type_expenseItems" :options.sync="options" :page.sync="page"
                                      :items-per-page="perPage" :server-items-length="totalTypeExpenses" :sort-by.sync="sortBy"
                                      :sort-desc.sync="sortDir" :loading="loading" :locale="lang"
                                      :loading-text="$t('loading_please_wait')"
                                      hide-default-footer
                                      :hide-default-header="(!$vuetify.breakpoint.xsOnly)"
                                      class="elevation-0"
                                      :item-class="rowClass">
                            <template v-slot:header="{ props: { headers } }" v-if="(!$vuetify.breakpoint.xsOnly)">
                                <thead>
                                <tr class="rowHeadClass Table2">
                                    <th v-for="h in headers" :key="h.value" class="table_header">
                                        <span>{{h.text}}</span>
                                    </th>
                                </tr>
                                </thead>
                            </template>
                            <template v-slot:item.name="{ item }">
                                <div :class="item.deleted ? 'red--text' : ''">
                                    <span  class="cursor-pointer font_weight_600" @click="editTypeExpense(item)" >
                                        {{  item.name }}
                                        {{ item.deleted ? ' (' + $t('deleted') + ')' : '' }}
                                    </span>
                                    <v-icon v-if="!item.deleted" @click="deleteTypeExpense(item)" small>mdi-delete-outline</v-icon>
                                </div>
                            </template>
                        </v-data-table>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-pagination v-show="totalTypeExpenses > perPage" v-model="page" :total-visible="totalVisiblePag" class="pagination_table"
                                      :length="pageCount" :disabled="loading"></v-pagination>
                    </v-card-actions>
                </v-card>
            </div>
        </div>
    </v-container>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mapGetters} from "vuex"
import SettingMenu from "../components/SettingMenu";

export default {
    name: "TypeExpenses",
    components: {
        ValidationProvider,
        ValidationObserver,
        SettingMenu
    },
    data() {
        return {
            type_expense: null,
            code: null,
            sortBy: "name",
            sortDir: true,
            loading: false,
            options: {},
            page: 1,
            pageCount: 0,
            perPage: 5,
            totalVisiblePag: 7,
            totalTypeExpenses: 0,
            type_expenseItems: [],
            headers: [
                {
                    text: this.$t('name'),
                    align: "left",
                    sortable: true,
                    value: "name"
                },
                {
                    text: this.$t('business_process'),
                    align: "left",
                    sortable: false,
                    value: "business_process",
                },
            ],
            filter_show:false,
            business_process: 3,
            businessProcessItems: []
        }
    },
    computed: {
        ...mapGetters(['BusinessProcessId','lang', 'itemsPerPage', 'perPageItems']),
    },
    async mounted() {
        this.business_process = this.BusinessProcessId;
        this.options.itemsPerPage = this.perPage = this.itemsPerPage
        await this.getBusinessProcesses();
    },
    watch: {
        options: {
            handler() {
                this.getTypeExpenses()
            },
            deep: false
        }
    },
    methods: {
        handleFilterShow(action){
            this.filter_show = action;
        },
        rowClass(){
            return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass Table2' : 'rowMobiClass';
        },
        editTypeExpense(item) {
            this.$router.push({
                name: 'type_expense.edit',
                params: {
                    id: item.id
                }
            })
        },
        async getTypeExpenses() {
            var _this = this
            this.progress = 0
            this.loading = true
            const {
                sortBy,
                sortDesc,
                page,
                itemsPerPage
            } = this.options
            let params = {}
            if (sortBy[0] !== undefined) {
                params.sortBy = sortBy[0]
            }
            if (sortDesc[0] !== undefined) {
                params.sortDir = sortDesc[0] ? 'asc' : 'desc'
            }
            if (page !== undefined) {
                params.page = page
            }
            if (itemsPerPage !== undefined) {
                params.perPage = itemsPerPage
            }

            if (this.type_expense) {
                params.type_expense = this.type_expense
                params.page = 1
            }
            if (this.code) {
                params.code = this.code
                params.page = 1
            }

            if (this.business_process) {
                params.business_process = this.business_process
            }
            await this.$http
                .get("admin/type_expense", {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.type_expenseItems = res.body.data
                    this.page = res.body.meta.current_page
                    this.totalTypeExpenses = res.body.meta.total
                    this.pageCount = res.body.meta.last_page
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_list_type_expenses'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        async deleteTypeExpense(item) {
            if (confirm(this.$t('delete_type_expense'))) {
                var _this = this
                this.loading = true
                await this.$http
                    .delete(`admin/type_expense/${item.id}`)
                    .then(res => {
                        this.$toastr.success(this.$t('type_expense_has_been_deleted'))
                        this.getTypeExpenses()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('type_expense_has_not_been_deleted'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },
        async getBusinessProcesses() {
            this.loading = true
            let params = {}
            await this.$http
                .get("admin/business_process", {
                    params: params,
                })
                .then(res => {
                    this.businessProcessItems = res.body.data
                })
                .catch(err => {
                    this.businessProcessItems = []
                })
                .finally(end => {
                    this.loading = false
                })
        },
    }
}
</script>
